import * as React from "react";
import AuthApp from "authentication";
import SEO from "../components/seo";

const AuthenticationPage: React.FunctionComponent<{}> = (): JSX.Element => {
  return (
    <React.Fragment>
      <SEO title="Authentication" />
      <AuthApp basepath="auth" />
    </React.Fragment>
  );
};

export default AuthenticationPage;
